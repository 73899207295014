const location = window.location;
const navLinksRef = document.querySelectorAll('a.nav__link');

navLinksRef.forEach(link => {
  const linkHref = link?.getAttribute('href');
  let locationPathname = location?.pathname;
  if (location?.pathname?.endsWith('/') && location?.pathname?.length > 1)
    locationPathname = location?.pathname?.slice(
      0,
      location?.pathname?.length - 1
    );

  if (linkHref === locationPathname) link?.classList.add('active');
  else link?.classList.remove('active');
});
