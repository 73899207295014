const headerRef = document.querySelector('header');
const htmlRef = document.querySelector('html');

window.addEventListener('scroll', onWindowScroll);

function onWindowScroll() {
  const windowPageYOffset = window.pageYOffset;
  const heroBottom = headerRef.getBoundingClientRect().bottom;

  if (windowPageYOffset > heroBottom / 4) {
    headerRef.classList.add('header--light');
    htmlRef.classList.add('header--light');
  } else if (windowPageYOffset <= heroBottom / 4) {
    headerRef.classList.remove('header--light');
    htmlRef.classList.remove('header--light');
  }
}
