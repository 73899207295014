import { hideElement, showElement } from '../../common/js';

const burgerMenuRef = document.querySelector('[data-burger-menu]');
const burgerMenuBtnRef = document.querySelector('[data-burger-menu-btn]');

updateSize();

window.addEventListener('resize', updateSize);
burgerMenuBtnRef.addEventListener('click', toggleBurgerMenu);
// burgerMenuRef.addEventListener('click', toggleBurgerMenu);

function toggleBurgerMenu() {
  const expanded = burgerMenuBtnRef.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) hideElement(burgerMenuRef);
  else showElement(burgerMenuRef);

  burgerMenuBtnRef.setAttribute('aria-expanded', !expanded);
  document.body.classList.toggle('burgerMenuIsOpen');
}

function hideBurgerMenu() {
  hideElement(burgerMenuRef);
  burgerMenuBtnRef.setAttribute('aria-expanded', false);
  document.body.classList.remove('burgerMenuIsOpen');
}
function showBurgerMenu() {
  showElement(burgerMenuRef);
  burgerMenuBtnRef.setAttribute('aria-expanded', true);
  document.body.classList.remove('burgerMenuIsOpen');
}

function updateSize() {
  const innerWidth = window.innerWidth;

  if (innerWidth <= 768) hideBurgerMenu();
  // else showBurgerMenu();
}
